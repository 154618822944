import * as React from "react";

interface IProps {
    href?: string
    label: string    
}

const VoLink = ({ href, label }: IProps) => {

    return <a className="vl-link" target="_blank" rel="noreferrer" href={href} >
        {label}
        <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
        <span className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external" aria-hidden="true"></span>
    </a>
}
export default VoLink;