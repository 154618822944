import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.scss';
import '@govflanders/vl-ui-core';
import VoLink from './vo-widgets/VoLink';
import VoAlert from './vo-widgets/VoAlert';
import VoRadio from './vo-widgets/VoRadio';
import VoCheckBoxTile from './vo-widgets/VoCheckboxTile';

interface IData {
    typePersoon?: number;
    accepteerVoorwaarden: boolean
}

interface IProps {
    apiUrl: string
    downloadKeyValidMinutes?: number
}

const App = ({ apiUrl, downloadKeyValidMinutes }: IProps) => {

    const persoonTypenOptions = useMemo(() => [
        { value: 1, label: "Instrumenterend ambtenaar/notaris (i.h.k.v de overdracht van een zakelijk recht op een roerend of onroerend goed)" },
        { value: 2, label: "(Kandidaat-)huurder" },
        { value: 3, label: "(Kandidaat-)koper" },
        { value: 4, label: "Tussenpersoon bij de verkoop of verhuur van woningen (zoals immobiliënkantoor, makelaar,…)" },
        { value: 5, label: "Personeelslid, vermeld in artikel 3.26, vierde lid van het Besluit Vlaamse Codex Wonen van 2021*" },
    ], [])

    const [formData, setFormData] = useState<IData>({ accepteerVoorwaarden: false });
    const sendBtnDisabled = useMemo(() => !formData.accepteerVoorwaarden || typeof formData.typePersoon === "undefined", [formData])
    const [downloadKey, setDownloadKey] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    //Download is valid for {downloadKeyValidMinutes} minutes. This timer resets the page
    useEffect(() => {
        if (downloadKey) {
            const tid: number = window.setTimeout(() => {
                setDownloadKey("");
            }, 1000 * (60 * (downloadKeyValidMinutes || 10)) - 5)
            return () => window.clearTimeout(tid);
        }
    }, [downloadKey, downloadKeyValidMinutes])

    const sendForm = useCallback(() => {
        setError("");
        setLoading(true);
        fetch(`${apiUrl}/accept-register`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }).then(resp => resp.json().then(respData => setDownloadKey(respData?.downloadKey || "")))
            .catch(() => setError("Fout tijdens het verzenden van de gegevens. Probeer het later opnieuw."))
            .finally(() => setLoading(false));
    }, [formData, apiUrl])

    const persTypeChangeHandler = useCallback((value: string) => {
        setError("");
        setFormData(prev => ({
            ...prev,
            typePersoon: +value
            //persoonTypen: checked
            //    ? [...prev.persoonTypen, +value]
            //    : prev.persoonTypen.filter(p => p !== +value)
        }))
    }, []);

    const voorwaardenCheckedChangedHandler = (checked: boolean) => {
        setError("");
        setFormData(prev => ({ ...prev, accepteerVoorwaarden: checked }))
    }

    //----------
    //RENDER
    //----------
    if (downloadKey)
        return <>
            <p><span className="vl-icon vl-vi vl-vi-file-office-pdf" aria-hidden="true"></span> Huidig <a
                href={`${apiUrl}/download-register/pdf/${downloadKey}`}>register van herstelvorderingen</a> (pdf)</p>
            <p><span className="vl-icon vl-vi vl-vi-file-office-xls" aria-hidden="true"></span> Huidig <a
                href={`${apiUrl}/download-register/csv/${downloadKey}`}>register van herstelvorderingen</a> (csv)</p>
        </>

    return <div className="vl-grid vl-grid--is-stacked-small">

        <div className="vl-col--1-1">
            <label className="vl-form__label vl-form__label--block">
                Ik bevestig dat ik behoor tot een van de onderstaande categorieën van personen en raadpleeg
                het register van herstelvorderingen enkel met het oog op de bescherming van mijn rechtmatige belangen
                of in het kader van de uitoefening van de mij toekomende bevoegdheden. <span className="red">*</span>
            </label>

            {persoonTypenOptions.map((item, idx) => <VoRadio
                key={idx}
                id={"persType-" + idx}
                name="persoonTypen"
                value={item.value}
                label={item.label}
                checked={formData.typePersoon === item.value}
                valueChange={persTypeChangeHandler} />)}

            <p className="vl-form__annotation">
                *personeelsleden van het agentschap Wonen-Vlaanderen, gemeenten en intergemeentelijke samenwerkingsverbanden
                die taken uitvoeren i.h.k.v. de woningkwaliteitshandhaving)
            </p>
        </div>

        <div className="vl-col--1-1">            
            <VoCheckBoxTile
                name="accepteerVoorwaarden"
                checked={formData.accepteerVoorwaarden}
                checkedChanged={voorwaardenCheckedChangedHandler}
                value={1}
                label="Voorwaarden*"
            >
                <span>
                    Ik aanvaard dat de gegevens in het register van herstelvorderingen
                    een vertrouwelijk karakter hebben en zal deze niet actief verder verspreiden,
                    tenzij wanneer dit noodzakelijk is in het kader van de uitvoering van mijn werkzaamheden.
                    Mijn persoonsgegevens worden niet verwerkt naar aanleiding van de consultatie
                    van het register van herstelvorderingen. Ik neem akte van de <VoLink
                        href="https://www.wonenvlaanderen.be/privacy"
                        label="privacyverklaring van het agentschap Wonen-Vlaanderen"
                    /> en de aanvullende <VoLink
                        href="https://www.wonenvlaanderen.be/informatie-over-wonen-vlaanderen/privacyverklaring-wonen-vlaanderen-afdeling-woningkwaliteit"
                        label="privacyverklaring van de afdeling woningkwaliteit van dit agentschap" />.
                </span>
            </VoCheckBoxTile>
        </div>

        <div className="vl-col--1-1">
            <button
                className={"vl-button " + (sendBtnDisabled ? "vl-button--disabled" : "") + (loading ? "vl-button--loading" : "")}
                vl-button="true"
                type="button"
                onClick={sendForm}
                disabled={sendBtnDisabled || loading} >
                <span className="vl-button__label">Register aanvragen</span>
            </button>
        </div>

        {error && <div className="vl-col--1-1"><VoAlert message={error} /></div>}

    </div>
}
export default App;
