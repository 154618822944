import * as React from "react";

interface IProps {
    title?: string
    message?: string
    children?: React.ReactNode
    //type  error, warning, ... todo: make it more generic (when needed)
}

const VoAlert = ({ message, children, title }: IProps) => {

    return <div className="vl-alert vl-alert--error" role="alertdialog">
        <div className="vl-alert__icon">
            <i className="vl-vi vl-vi-warning" aria-hidden="true"></i>
        </div>
        <div className="vl-alert__content">
            <p className="vl-alert__title">{title || "Opgelet!"}</p>
            <div className="vl-alert__message">
                {message && <p>{message}</p>}
            </div>
            {children}
        </div>
    </div>
}
export default VoAlert; 