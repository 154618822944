import * as React from "react";
import { useCallback } from "react";

interface IProps {
    id?: string;
    name: string;
    value?: string | number;
    label?: string;
    children?: React.ReactNode;
    checked: boolean;
    valueChange?: (value: string) => void;
}

const VoRadio = ({ id, name, value, label, children, checked, valueChange }: IProps) => {

    const changeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        valueChange?.(e.target.value), [valueChange]);

    return <label className="vl-radio vl-radio--block" htmlFor={id} >
        <input className="vl-radio__toggle" type="radio" id={id} name={name} value={value} onChange={changeHandler} checked={checked} />
        <div className="vl-radio__label">{label}</div>
        {children}
    </label>
}
export default VoRadio;