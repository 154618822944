import * as React from "react";
import { useCallback } from "react";

interface IProps {
    id?: string;
    name: string;
    value: string | number;
    label?: string;
    text?: string;
    checkedChanged?: (checked: boolean, value: string) => void;
    checked: boolean;
    children?: React.ReactNode;
}

const VoCheckBoxTile = ({ id, name, value, label, text, checked, checkedChanged, children }: IProps) => {

    const checkedHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        checkedChanged?.(e.target.checked, e.target.value), [checkedChanged]);

    return <div className="vl-checkbox-tile">
        <input
            className="vl-checkbox-tile__input"
            type="checkbox"
            id={id || name}
            name={name}
            value={value}
            onChange={checkedHandler}
            checked={checked} />
        <label htmlFor={id || name} className="vl-checkbox-tile__label js-vl-equal-height">
            <span className="vl-checkbox-tile__title">{label}</span>
            <span className="vl-checkbox-tile__info">{text || children}</span>
            </label>
    </div>;
}
export default VoCheckBoxTile;